import React from 'react';
import {useParams} from "react-router-dom";
import {
    MDBBtn,
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBCardBody,
    MDBInput,
    MDBIcon,
    MDBCheckbox
}
    from 'mdb-react-ui-kit';
import PhoneInput from "react-phone-input-2";
import {ErrorNotify, SuccessNotify} from "../../alerts";
import axios from "axios";
import {BASE_URL} from "../../utils/instance/Base";

function Index(props) {
    const {phone, chatId} = useParams()

    function registerUser(e) {
        e.preventDefault()
        const firstName = e.target[0].value;
        const lastName = e.target[1].value;
        const password = e.target[2].value;
        const repeatPassword = e.target[3].value;
        if (repeatPassword === password) {
            axios({
                url: `${BASE_URL}/auth/web-app/register`,
                method: "POST",
                data: {firstName, lastName, password, chatId, phone}
            }).then((res) => {
                if (res?.data?.ok) {
                    SuccessNotify(res?.data?.message)
                    window.Telegram.WebApp.close()
                } else {
                    ErrorNotify(res?.data?.message)
                }
            }).catch((err) => {
                ErrorNotify("Xatolik yuzaga keldi!")
            })
        } else {
            ErrorNotify("Parolni qayta tekshirib kiriting!")
        }
    }

    return (
        <div>
            <MDBContainer fluid>

                <MDBRow className='d-flex justify-content-center align-items-center h-100'>
                    <MDBCol col='12'>

                        <MDBCard className='bg-white my-5 mx-auto' style={{borderRadius: '1rem', maxWidth: '500px'}}>
                            <form onSubmit={registerUser}>
                                <MDBCardBody className='p-5 w-100 d-flex flex-column'>

                                    <h2 className="fw-bold mb-2 text-center">RO'YXATDAN O'TISH</h2>
                                    <input minLength={5} type="text" placeholder={"Ismingizni kiriting"}
                                           className={'form-control mb-3'}
                                           required/>
                                    <input minLength={5} type="text" placeholder={"Familiyangizni kiriting"}
                                           className={'form-control mb-3'}
                                           required/>
                                    <input minLength={8} type="password" placeholder={"Parol yarating"}
                                           className={'form-control mb-3'}
                                           required/>
                                    <input minLength={8} type="password" placeholder={"Parolni takrorlang"}
                                           className={'form-control mb-3'}
                                           required/>
                                    <MDBBtn size='lg'>
                                        RO'YXATDAN O'TISH
                                    </MDBBtn>
                                </MDBCardBody>
                            </form>
                        </MDBCard>

                    </MDBCol>
                </MDBRow>

            </MDBContainer>
        </div>
    );
}

export default Index;