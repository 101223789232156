import React, {useEffect, useState} from 'react';
import BottomNav from "../../ui/BottomNav";
import instance from "../../utils/instance";
import ProductCard from "../../ui/ProductCard";
import {Pagination} from "@mui/material";

function Index(props) {
    const [categories, setCategories] = useState([])
    const [category, setCategory] = useState("")
    const [search, setSearch] = useState("")
    const [products, setProducts] = useState([])
    const [totalPages, setTotalPages] = useState(1)

    async function getCategories() {
        const res = await instance("/category/all", "GET");
        if (res?.data?.ok) {
            setCategories(res?.data?.data)
        }
    }

    async function getProducts(page = 1) {
        if (category === "") {
            const res = await instance(`/product/f?page=${page}&q=${search}`);
            if (res?.data?.ok) {
                setProducts(res?.data?.data?.content)
                setTotalPages(res?.data?.data?.totalPages)
            }
        } else {
            const res = await instance(`/product/f?page=${page}&q=${search}&cid=${category}`);
            if (res?.data?.ok) {
                setProducts(res?.data?.data?.content)
                setTotalPages(res?.data?.data?.totalPages)
            }
        }
    }

    useEffect(() => {
        getProducts()
    }, [search, category]);

    useEffect(() => {
        getCategories()
    }, []);
    return (
        <div style={{overflowX: "auto"}} className={'web-bg-color w-100 vh-100 pt-2'}>
            <div style={{width: "100%", padding: 10}}>
                <select value={category} onChange={(e) => setCategory(e.target.value)} className={'form-control mb-3'}>
                    <option value="" disabled selected>TURKUM BO'YICHA FILTIRLASH</option>
                    <option value="">BARCHA TURKUMLAR</option>
                    {categories?.map((item) => <option value={item?.id}>{item?.name}</option>)}
                </select>
                <input value={search} onChange={(e) => setSearch(e.target.value)} type="text" placeholder={"IZLASH..."}
                       className={'form-control mb-3'}/>
                <div style={{
                    width: "100%",
                    overflowX: "auto",
                    overflowY: "auto",
                    flexWrap: "wrap",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-evenly",
                    marginBottom: 70
                }}>
                    {products?.map((item) => <ProductCard item={item} title={item?.name} img={item?.photo}
                                                          price={item?.price}/>)}
                    {totalPages > 1 ? <div style={{
                        width: "100%",
                        padding: 5,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: 20
                    }}>
                        <Pagination onChange={(event, page) => getProducts(page)} count={totalPages} color="primary"/>
                    </div> : ""}
                </div>
            </div>
            <BottomNav/>
        </div>
    );
}

export default Index;