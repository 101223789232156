import axiosInterceptor from "./axiosInterceptor";
import {BASE_URL} from "./Base";
import {ErrorNotify} from "../../alerts";



export default function instance(url, method, data) {
    let item = localStorage.getItem("id_session");
    return axiosInterceptor({
        baseURL: BASE_URL,
        url,
        method: method,
        data,
        headers: {
            "Authorization": item,
        },
    }).catch((err) => {
        if (err.response && err.response.status === 401) {
            return axiosInterceptor({
                url: BASE_URL + "/auth/refresh?token=" + localStorage.getItem("id_refresh_session"),
                method: "POST"
            }).then((res) => {
                localStorage.setItem("id_session", res.data);
                return axiosInterceptor({
                    baseURL: BASE_URL,
                    url,
                    method: method,
                    data,
                    headers: {
                        "Authorization": localStorage.getItem("id_session"),
                    },
                });
            }).catch((refreshErr) => {
                window.location = "/login";
                localStorage.removeItem("id_session");
                localStorage.removeItem("id_refresh_session");
                throw refreshErr;
            });
        } else if (err.response && (err.response.status === 404 || err.response.status === 500)) {
            ErrorNotify(err.response.data);
        } else if (err.response && (err.response.status === 403)) {
            ErrorNotify("Ruxsat berilmagan!")
        }
    });
}
