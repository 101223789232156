import React, {useEffect, useState} from 'react';
import BottomNav from "../../ui/BottomNav";
import instance from "../../utils/instance";
import {MDBBadge, MDBBtn, MDBTable, MDBTableHead, MDBTableBody, MDBIcon} from 'mdb-react-ui-kit';
import format from "../../utils/instance/format";
import {Pagination} from "@mui/material";
import {
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
} from 'mdb-react-ui-kit';
import {PHOTO_URL} from "../../utils/instance/Base";

function Index(props) {
    const [basicModal, setBasicModal] = useState(false);

    const toggleOpen = () => setBasicModal(!basicModal);
    const [orders, setOrders] = useState([])
    const [items, setItems] = useState([])
    const [totalPages, setTotalPages] = useState(1)

    async function getOrders(page = 1) {
        const res = await instance(`/order?page=${page}`, "GET");
        if (res.data.ok) {
            setOrders(res.data.data.content)
            setTotalPages(res.data.data.totalPages)
        }
    }

    useEffect(() => {
        getOrders()
    }, []);

    async function getOrderItems(id) {
        const res = await instance(`/order/items/${id}`, "GET");
        if (res?.data?.ok) {
            setItems(res?.data?.data)
            toggleOpen()
        }
    }

    return (
        <div style={{overflowX: "auto"}} className={'web-bg-color w-100 vh-100 pt-2'}>
            {orders.length === 0 ? <h6 className={'text-center'}>Buyurtmalar bo'sh</h6> :
                <div style={{marginBottom: 80, overflowY: "auto"}}>
                    <h5 className={'text-center'}>Mening buyurtmalarim</h5>
                    <MDBTable align='middle'>
                        <MDBTableHead>
                            <tr>
                                <th scope='col'>BUYURTMA RAQAMI</th>
                                <th scope='col'>MANZIL</th>
                                <th scope='col'>BUYURTMA HOLATI</th>
                                <th scope='col'>TELEFON RAQAM</th>
                                <th scope='col'>JAMI SUMMA</th>
                                <th scope='col'>BUYURTMA SANASI</th>
                                <th scope='col'>BUYURTMA MAHSULOTLARI</th>
                            </tr>
                        </MDBTableHead>
                        <MDBTableBody>
                            {orders?.map((item) => <tr key={item?.id}>
                                <td>
                                    <p className='fw-bold mb-1'>{item?.orderId}</p>
                                </td>
                                <td>
                                    <p className='fw-normal mb-1'>{item?.address}</p>
                                    <p className='text-muted mb-0'>{item?.region}</p>
                                </td>
                                <td>
                                    {item?.status === "PENDING" ? <MDBBadge color='warning' pill>
                                        KUTILMOQDA
                                    </MDBBadge> : item?.status === "CONFIRMED" ? <MDBBadge color='success' pill>
                                        TASDQILANDI
                                    </MDBBadge> : item?.status === "SHIPPING" ? <MDBBadge color='info' pill>
                                        YETKAZILMOQDA
                                    </MDBBadge> : item?.status === "DELIVERED" ? <MDBBadge color='secondary' pill>
                                        YETKAZIB BERILDI
                                    </MDBBadge> : item?.status === "CANCELLED" ? <MDBBadge color='danger' pill>
                                        BEKOR QILINDI
                                    </MDBBadge> : ""}
                                </td>
                                <td>{item?.phone}</td>
                                <td>{format(item?.totalPrice)} UZS</td>
                                <td>{new Date(item?.date).toLocaleDateString()} | {new Date(item?.date).toLocaleTimeString()}</td>
                                <td>
                                    <MDBBtn onClick={() => getOrderItems(item?.id)} color='link' rounded size='sm'>
                                        <MDBIcon fas icon="info-circle"/>
                                    </MDBBtn>
                                </td>
                            </tr>)}
                        </MDBTableBody>
                    </MDBTable>
                </div>}
            {totalPages > 1 ? <div style={{
                width: "100%",
                padding: 5,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: 80
            }}>
                <Pagination onChange={(event, page) => getOrders(page)} count={totalPages} color="primary"/>
            </div> : ""}
            <BottomNav/>


            <MDBModal open={basicModal} onClose={() => setBasicModal(false)} tabIndex='-1'>
                <MDBModalDialog>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>Modal title</MDBModalTitle>
                            <MDBBtn className='btn-close' color='none' onClick={toggleOpen}></MDBBtn>
                        </MDBModalHeader>
                        <MDBModalBody style={{overflowY: "auto"}}>
                            <MDBTable align='middle'>
                                <MDBTableHead>
                                    <tr>
                                        <th scope='col'>MAHSULOT NOMI</th>
                                        <th scope='col'>MAHSULOT NARXI</th>
                                        <th scope='col'>MAHSULOT MIQDORI</th>
                                    </tr>
                                </MDBTableHead>
                                <MDBTableBody>
                                    {items?.map((item) => <tr key={item?.id}>
                                        <td>
                                            <p className='fw-bold mb-1'>{item?.productName}</p>
                                        </td>
                                        <td>
                                            <p className='fw-normal mb-1'>{format(item?.price)} UZS</p>
                                        </td>
                                        <td>{item?.quantity}</td>
                                    </tr>)}
                                </MDBTableBody>
                            </MDBTable>
                        </MDBModalBody>

                        <MDBModalFooter>
                            <MDBBtn color='secondary' onClick={toggleOpen}>
                                CHIQISH
                            </MDBBtn>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
        </div>
    );
}

export default Index;