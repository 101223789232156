import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {PHOTO_URL} from "../utils/instance/Base";
import format from "../utils/instance/format";
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import instance from "../utils/instance";
import {ErrorNotify, SuccessNotify} from "../alerts";

export default function ProductCard({title, img, price, item}) {

    const addItemToBasket = async (item) => {
        const res = await instance(`/basket?productId=${item?.id}`, "POST");
        if (res?.data?.ok) {
            SuccessNotify(res?.data?.message)
        } else {
            ErrorNotify(res?.data?.message)
        }
    };

    const truncateText = (text, maxLength) => {
        if (text.length <= maxLength) {
            return text;
        }
        return text.slice(0, maxLength) + '...';
    };

    function moreInfo() {
        window.Telegram.WebApp.openLink("https://github.com/")
    }

    return (
        <Card sx={{minWidth: 200, marginTop: 5}}>
            <CardMedia
                sx={{height: 100}}
                image={PHOTO_URL + img}
                title={title}
            />
            <CardContent>
                <Typography gutterBottom variant="p" component="div">
                    {truncateText(title, 17)}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    {format(price)} UZS
                </Typography>
            </CardContent>
            <CardActions>
                <Button onClick={() => addItemToBasket(item)} size="small"><AddShoppingCartIcon/></Button>
                <Button onClick={() => moreInfo()} size="small">Batafsil &nbsp; <ArrowOutwardIcon/></Button>
            </CardActions>
        </Card>
    );
}