import React from 'react';
import {useParams} from "react-router-dom";
import {MDBBtn, MDBCard, MDBCardBody, MDBCol, MDBContainer, MDBRow} from 'mdb-react-ui-kit';
import PhoneInput from "react-phone-input-2";
import axios from "axios";
import {BASE_URL} from "../../utils/instance/Base";
import {ErrorNotify, SuccessNotify} from "../../alerts";

function Index(props) {
    const {phone, chatId} = useParams()

    function loginUser(e) {
        e.preventDefault()
        const password = e.target[1].value;
        axios({
            url: `${BASE_URL}/auth/web-app/login`,
            method: "POST",
            data: {password, phone, chatId}
        }).then((res) => {
            if (res?.data?.ok) {
                SuccessNotify(res?.data?.message)
                window.Telegram.WebApp.close()
            } else {
                ErrorNotify(res?.data?.message)
            }
        }).catch((err) => {
            console.error(err)
            ErrorNotify("Xatolik yuzaga keldi!")
        })
    }

    return (
        <div>
            <MDBContainer fluid>

                <MDBRow className='d-flex justify-content-center align-items-center h-100'>
                    <MDBCol col='12'>

                        <MDBCard className='bg-white my-5 mx-auto' style={{borderRadius: '1rem', maxWidth: '500px'}}>
                            <form onSubmit={loginUser}>
                                <MDBCardBody className='p-5 w-100 d-flex flex-column'>
                                    <h2 className="fw-bold mb-2 text-center">TIZIMGA KIRISH</h2>
                                    <PhoneInput
                                        value={phone}
                                        disabled
                                        inputProps={{
                                            required: true
                                        }}
                                        inputClass={'w-100'}
                                        country={'uz'}
                                        onlyCountries={['uz']}
                                    />
                                    <input type="password" required minLength={8} className={'form-control mt-3 mb-3'}
                                           placeholder={"Parolni kiriting"}/>
                                    <MDBBtn size='lg'>
                                        TIZIMGA KIRISH
                                    </MDBBtn>
                                </MDBCardBody>
                            </form>
                        </MDBCard>

                    </MDBCol>
                </MDBRow>

            </MDBContainer>
        </div>
    );
}

export default Index;