import React, {useEffect, useState} from 'react';
import {Route, Routes, useLocation} from "react-router-dom";
import {Toaster} from "react-hot-toast";
import Home from "./components/pages/Home";
import axios from "axios";
import {BASE_URL} from "./components/utils/instance/Base";
import Loader from "./components/ui/Loader";
import Shop from "./components/pages/Shop";
import Basket from "./components/pages/Basket";
import Order from "./components/pages/Order";
import Register from "./components/pages/Register";
import Login from "./components/pages/Login";

function App(props) {
    const location = useLocation();
    const webApp = window.Telegram.WebApp;
    const userId = webApp.initDataUnsafe.user.id;
    const [loader, setLoader] = useState(false)
    const permissions = [
        {url: "/", roles: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_SELLER", "ROLE_USER"]},
        {url: "/shop", roles: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_SELLER", "ROLE_USER"]},
        {url: "/basket", roles: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_SELLER", "ROLE_USER"]},
        {url: "/orders", roles: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_SELLER", "ROLE_USER"]},
    ];

    function generateUserToken(id) {
        const matchingPermission = permissions.find(item => item.url === location.pathname);
        if (matchingPermission) {
            axios({
                url: `${BASE_URL}/auth/web?chatId=${id}`,
                method: "GET"
            }).then((res) => {
                if (res?.data?.ok) {
                    localStorage.setItem("id_session", res?.data?.data)
                    setLoader(false)
                } else {
                    webApp.close()
                }
            }).catch((err) => {
                webApp.close()
            })
        } else {
            setLoader(false)
        }
    }

    useEffect(() => {
        generateUserToken(userId)
    }, [userId, location.pathname]);
    return (
        <div>
            {loader ? <Loader/> : <><Toaster position="top-right"
                                             reverseOrder={false}/>
                <Routes>
                    <Route path={'/'} element={<Home/>}/>
                    <Route path={'/shop'} element={<Shop/>}/>
                    <Route path={'/basket'} element={<Basket/>}/>
                    <Route path={'/orders'} element={<Order/>}/>
                    <Route path={'/register/:phone/:chatId'} element={<Register/>}/>
                    <Route path={'/login/:phone/:chatId'} element={<Login/>}/>
                </Routes></>}
        </div>
    );
}

export default App;