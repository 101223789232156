import React, {useEffect, useState} from 'react';
import BottomNav from "../../ui/BottomNav";
import instance from "../../utils/instance";
import {MDBIcon} from "mdb-react-ui-kit";
import format from "../../utils/instance/format";
import {
    MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
} from 'mdb-react-ui-kit';
import PhoneInput from "react-phone-input-2";
import {ErrorNotify, SuccessNotify} from "../../alerts";
import {useNavigate} from "react-router-dom";

function Index(props) {
    const navigate = useNavigate()
    const [basicModal, setBasicModal] = useState(false);
    const [user, setUser] = useState(null)
    const [regions, setRegions] = useState([])
    const [branches, setBranches] = useState([])
    const [region, setRegion] = useState('')
    const toggleOpen = () => setBasicModal(!basicModal);
    const [basket, setBasket] = useState([])
    const [totalPrice, setTotalPrice] = useState(0)

    async function getRegions() {
        const res = await instance("/region", "GET");
        if (res.data.ok) {
            setRegions(res.data.data)
        }
    }

    async function getShippingBranches() {
        if (region) {
            const res = await instance(`/shipping-branch/${region}`, "GET");
            if (res.data.ok) {
                setBranches(res.data.data)
            } else {
                ErrorNotify(res.data.message)
            }
        }
    }

    useEffect(() => {
        getShippingBranches()
    }, [region]);

    async function getBasket() {
        const res = await instance("/basket", "GET");
        if (res?.data?.ok) {
            setBasket(res?.data?.data)
        }
    }

    useEffect(() => {
        getBasket()
        getRegions()
    }, []);
    useEffect(() => {
        calculateTotalPrice();
    }, [basket]);

    async function changeCount(id, status) {
        const res = await instance(`/basket/change?id=${id}&status=${status}`, "GET");
        if (res?.data?.ok) {
            await getBasket()
        }
    }

    function calculateTotalPrice() {
        const total = basket.reduce((sum, item) => sum + (item.price * item.qty), 0);
        setTotalPrice(total);
    }

    async function checkUser() {
        const res = await instance("/user/me", "GET");
        if (res.data.ok) {
            setUser(res.data.data)
            toggleOpen()
        }
    }

    async function createOrder(e) {
        e.preventDefault()
        const branch = e.target[1].value;
        const phone = e.target[2].value;
        const res = await instance("/order", "POST", {shippingBranchId: branch, phone, products: basket});
        if (res.data.ok) {
            await instance("/basket", "DELETE")
            toggleOpen()
            SuccessNotify(res.data.message)
            navigate("/orders")
        } else {
            ErrorNotify(res.data.message)
        }
    }

    return (
        <div style={{overflowX: "auto"}} className={'web-bg-color w-100 vh-100 pt-2'}>
            {basket?.length === 0 ? <h6 className={'text-center'}>Savat bo'sh</h6> : <div style={{marginBottom: 80}}>
                {basket?.map((item) => <div key={item?.basketId} className={'basket-card'}>
                    <h6 className={'text-start'}>{item?.productName}</h6>
                    <p className={'text-start'}>Narxi: {format(item?.price)} UZS</p>
                    <p className={'text-start'}>Umumiy narxi: {format(item?.price * item?.quantity)} UZS</p>

                    <div className={'btn-group'}>
                        <button onClick={() => changeCount(item?.basketId, "PLUS")} className={'btn btn-success'}>
                            <MDBIcon fas icon="plus"/>
                        </button>
                        <button className={'btn btn-primary'}>
                            {item?.qty}
                        </button>
                        <button onClick={() => changeCount(item?.basketId, "MINUS")} className={'btn btn-danger'}>
                            <MDBIcon fas icon="minus"/>
                        </button>
                    </div>

                </div>)}
                <div className={'basket-card'}>
                    <h6>JAMI SUMMA: {format(totalPrice)} UZS</h6>
                    <button onClick={checkUser} className={'btn btn-dark w-100 mt-3'}>
                        BUYURTMANI RASMIYLASHTIRISH
                    </button>
                </div>
            </div>}
            <BottomNav/>

            <MDBModal open={basicModal} onClose={() => setBasicModal(false)} tabIndex='-1'>
                <MDBModalDialog>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>BUYURTMANI RASMIYLASHTIRISH</MDBModalTitle>
                            <MDBBtn className='btn-close' color='none' onClick={toggleOpen}></MDBBtn>
                        </MDBModalHeader>
                        <form onSubmit={createOrder} id={'create-order'}>
                            <MDBModalBody>
                                <h6>Qabul qilish usuli va yetkazib berish manzili:</h6>
                                <select required value={region} onChange={(e) => setRegion(e.target.value)}
                                        className={'form-select mb-3'}>
                                    <option value="" disabled selected>VILOYATNI TANLANG</option>
                                    {regions?.map((item) => <option value={item?.id}
                                                                    key={item?.id}>{item?.name}</option>)}
                                </select>
                                {branches?.length > 0 ? <select required className={'form-select mb-3'}>
                                    <option value="" disabled selected>YETKAZIB BERISH MANZILINI TANLANG</option>
                                    {branches?.map((item) => <option value={item?.id}
                                                                     key={item?.id}>{item?.address}</option>)}
                                </select> : ""}
                                <PhoneInput
                                    value={user?.phone}
                                    inputProps={{
                                        required: true
                                    }}
                                    inputClass={'w-100'}
                                    country={'uz'}
                                    onlyCountries={['uz']}
                                />
                            </MDBModalBody>
                        </form>

                        <MDBModalFooter>
                            <MDBBtn color='secondary' onClick={toggleOpen}>
                                CHIQISH
                            </MDBBtn>
                            <MDBBtn form={'create-order'} color={"dark"}>RASMIYLASHTIRISH</MDBBtn>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
        </div>
    );
}

export default Index;