import React, {useEffect, useState} from 'react';
import BottomNav from "../../ui/BottomNav";
import ProductCard from "../../ui/ProductCard";
import instance from "../../utils/instance";
import Loader from "../../ui/Loader";

function Index(props) {
    const [bestProducts, setBestProducts] = useState([])
    const [newProducts, setNewProducts] = useState([])

    const [loader, setLoader] = useState(true)

    async function getBestProducts(page = 1) {
        const res = await instance(`/product/view?page=${page}&status=BEST`);
        if (res.data.ok) {
            setBestProducts(res?.data?.data?.content);
            await getNewProducts()
        }
    }

    async function getNewProducts(page = 1) {
        const res = await instance(`/product/view?page=${page}&status=NEW`);
        if (res.data.ok) {
            setNewProducts(res?.data?.data?.content);
            setLoader(false)
        }
    }

    useEffect(() => {
        getBestProducts()
    }, []);

    return (
        <>
            {
                loader ? <Loader/> : <div style={{overflowX: "auto"}} className={'web-bg-color w-100 vh-100 pt-2'}>
                    <h4 className={'text-center '}>OMMABOP MAHSULOTLAR</h4>
                    <div style={{
                        padding: 7,
                        display: "flex",
                        alignItems: "center",
                        gap: 10,
                        overflowY: "auto"
                    }}>
                        {bestProducts?.map((item) => <ProductCard item={item} title={item?.name} price={item?.price}
                                                                  id={item?.id}
                                                                  img={item?.photo}/>)}
                    </div>
                    <h4 className={'text-center  mt-4'}>YANGI MAHSULOTLAR</h4>
                    <div style={{
                        padding: 7,
                        display: "flex",
                        alignItems: "center",
                        gap: 10,
                        overflowY: "auto",
                        marginBottom: 60
                    }}>
                        {newProducts?.map((item) => <ProductCard item={item} title={item?.name} price={item?.price}
                                                                 id={item?.id}
                                                                 img={item?.photo}/>)}
                    </div>
                    <BottomNav/>
                </div>
            }</>
    );
}

export default Index;