import React, {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import HomeIcon from '@mui/icons-material/Home';
import InventoryIcon from '@mui/icons-material/Inventory';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import {useNavigate} from "react-router-dom";

export default function BottomNav() {
    const navigate = useNavigate()
    const webApp = window.Telegram.WebApp;
    const [value, setValue] = useState(0);
    useEffect(() => {
        const pathname = window.location.pathname;
        if (pathname === "/") {
            setValue(0)
            webApp.BackButton.hide()
        } else if (pathname === "/shop") {
            setValue(1)
            webApp.BackButton.show()
        } else if (pathname === "/basket") {
            setValue(2)
            webApp.BackButton.show()
        } else if (pathname === "/orders") {
            setValue(3)
            webApp.BackButton.show()
        }
    }, [window.location.pathname]);
    webApp.onEvent('backButtonClicked', () => {
        navigate(-1);
        webApp.BackButton.hide();
    });
    return (
        <Box style={{position: "absolute", bottom: 0, width: "100%"}}>
            <BottomNavigation
                style={{backgroundColor: "#ff9f1c"}}
                showLabels
                value={value}
                onChange={(event, newValue) => {
                    setValue(newValue);
                }}
            >
                <BottomNavigationAction onClick={() => navigate('/')} label="Asosiy" icon={<HomeIcon/>}/>
                <BottomNavigationAction onClick={() => navigate('/shop')} label="Mahsulotlar" icon={<InventoryIcon/>}/>
                <BottomNavigationAction
                    onClick={() => navigate("/basket")}
                    label="Savat"
                    icon={
                        <ShoppingCartIcon/>
                    }
                />
                <BottomNavigationAction onClick={() => navigate("/orders")} label="Buyurtmalar"
                                        icon={<LocalShippingIcon/>}/>
            </BottomNavigation>
        </Box>
    );
}
